<template>
  <div
    :class="{
      'app-hidden': commonStore.loader || commonStore.hidden,
    }"
  >
    <loader v-if="commonStore.loader" />
    <div class="content-app">
      <router-view :key="$route.fullPath" />
    </div>
    <AppBaseDialog v-if="commonStore.dialogParams.open" />
    <!-- <create-new-offer /> -->
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "./store/auth.store";
import { useCommonStore } from "@/store/common.store";
// import vArtNearApi from "@v-art-protocol/v-art-protocol-near-sdk";
import { onMounted, onUnmounted } from "vue";
import Loader from "./components/BaseLoader.vue";
import AppBaseDialog from "./components/BaseDialog.vue";
//import {signOut} from "./helpers/auth";
// import CreateNewOffer from "./pages/minter/CreateNewOffer.vue";
// import {concordInit} from "./helpers/concordium";

export default defineComponent({
  components: { Loader, AppBaseDialog },

  setup() {

    // concordInit();

    const router = useRouter();
    const authStore = useAuthStore();
    const commonStore = useCommonStore();

    onMounted(() => {
      window.addEventListener("message", messageListener);
      window.addEventListener("popstate", checkBackRouter);
    });

    onUnmounted(() => {
      window.removeEventListener("message", messageListener);
      window.removeEventListener("popstate", checkBackRouter);
    });

    function messageListener(message) {
      const request = message.data;
      // console.log("**** NFT APP messageListener", request.name);
      if (request.params && request.name == "changeRouteIframe") {
        console.log(request.params, " request.params");
        router.push({
          name: request.params.name,
          params: request.params.params,
        });
      }else if(request.params && request.name == "signOutRequest"){
        console.log("*** messageListener signOutRequest");
        parent.postMessage(
                {
                  name: "signOut",
                  params: {},
                },
                "*"
        );
        authStore.$patch({ user: "" });
        authStore.$patch({ network: "" });
        router.push({ name: "auth" });
      }
    }

    function checkBackRouter() {
      parent.postMessage(
        {
          name: "checkRoute",
          params: {
            name: router.currentRoute.value.name,
            // id: item.tokenId
          },
        },
        "*"
      );
    }

    // onMounted(async () => {
    //   try {
    //     await vArtNearApi.initContract(
    //       "valexa-license.testnet",
    //       "nft.valexa-license.testnet",
    //       "market.valexa-license.testnet",
    //       "testnet"
    //     );

    //     await vArtNearApi.initGraph(
    //       "https://api.thegraph.com/subgraphs/name/valexandrovich/valexa-license"
    //     );

    //     await vArtNearApi.initIpfs(
    //       "544fa186c3fd4aa39e8e",
    //       "7f1d4747551777aa7428a6cf29a0a6e582e827db7bbb3eed1e8aced8d18cc82d",
    //       "https://vart.mypinata.cloud/ipfs/"
    //     );

    //     let account = vArtNearApi.getAccount();

    //     if (account) authStore.$patch({ user: account });
    //     else authStore.$patch({ user: "" });
    //   } catch (error) {
    //     console.log("Initialization error: ", error);
    //   }
    // });

    // authStore.$subscribe((_, state) => {
    //   if (!state.user) {
    //     router.push({ name: "auth" });
    //   }
    // });

    return {
      commonStore,
    };
  },
});
</script>

<style>
* {
  margin: 0;
  padding: 0;
  /* font-family: "Stolzl"; */
}

.app-hidden {
  overflow: hidden;
  height: 100vh;
}
</style>
